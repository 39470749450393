<template>
  <v-container class="fitContainer mt-2">
    <v-row dense align="start" justify="center" class="fitContainer pa-1">
      <template>
        <v-col lg="3" md="3" sm="3" cols="6" xs="12" class="hTerco roundedItem">
          <div
            class="elevation-3 fill-height roundedItem"
            :style="{ position: 'relative' }"
          >
            <div
              :style="{
                zIndex: '1',
                width: '100%',
                position: 'absolute',
                'border-radius': '5px 5px 0px 0px',
              }"
              class="pl-2 py-2 ma-0 tertiary font-weight-bold body-2 elevation-0 black--text"
            >
              Não disponível no momento
            </div>
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import { mapMutations } from "vuex";

export default {
  name: "acess",

  methods: {
    ...mapMutations(["showmsg", "loading"]),
    async init() {
      if (
        (this.$route.query.token && this.$route.query.linkRedirect) ||
        (this.$route.params.token && this.$route.params.linkRedirect)
      ) {
        this.loading(true);
        let options = {
          noRedirect: true,
        };

        await this.$auth.createSession(
          {
            access_token: this.$route.query.token
              ? his.$route.query.token
              : this.$route.params.token,
          },
          options
        );

        let redirect =
          "/" +
          (this.$route.query.linkRedirect
            ? this.$route.query.linkRedirect
            : this.$route.params.linkRedirect);

        this.loading(false);
        this.$router.push(redirect);
      } else {
        this.showmsg({
          text: "Não disponível no momento!",
          type: "error",
        });
      }
    },
  },
  async created() {
    await this.init();
  },
};
</script>
